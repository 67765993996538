import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/core/auth.service';

/**
 * this guard is to check if users is not Logged, is different from private-area
 */
export const UserLoggedGuard: CanActivateFn = (route) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.isValid()) {
    const lang = route.params.lang;
    const redirect = route.data.redirect || 'home';
    return router.createUrlTree([lang, redirect]);
  } else {
    return true;
  }
};

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../services/core/auth.service';

/**
 * this guard is to check if user is logged and can visit a page or a set of pages
 */
@Injectable({
  providedIn: 'root'
})
export class PrivateAreaGuard  {

  /**
   * The constructor
   * @param authService
   * @param router
   */
  constructor(private authService: AuthService, private router: Router) {
  }

  /**
   * interface guard deciding if a route can be activated, if user is logged and can visit a page or a set of pages
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> | UrlTree {

    const account = this.authService.isValid();

    if (account || (route.paramMap.has('mode') && route.paramMap.get('mode') === 'fun')) {
      return true;
    }

    return this.router.navigate([this.router.url], {fragment: 'login', state: {redir: state.url}});
  }

  /**
   * interface guard deciding if a child route can be activated
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> | UrlTree {
    return this.canActivate(childRoute, state);
  }
}

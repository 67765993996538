import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { EnvironmentConfig } from '@bs/models/common/environment-config';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class CustomPageTitleStrategy extends TitleStrategy {

  constructor(private translateService: TranslateService, private readonly meta: Meta,
              private readonly title: Title, private config: EnvironmentConfig, @Inject(DOCUMENT) private document: Document) {
    super();

    if (config.features.ogImage) {
      meta.addTag({ property: 'og:image', content: config.features.ogImage });
      meta.addTag({ property: 'twitter:image', content: config.features.ogImage });
    }
  }

  override updateTitle(snapshot: RouterStateSnapshot): void {

    const selector = this.buildTitle(snapshot);
    const canonical = `https://www.${this.config.project}${snapshot.url}`;
    this.setCanonicalUrl(canonical);
    this.meta.updateTag({ property: 'og:url', content: canonical });

    if (selector) {

      if (selector.startsWith('seo-')) {
        this.translateService.get(`${selector}-title`).subscribe({
          next: content => {
            this.title.setTitle(content);
            this.meta.updateTag({ property: 'og:title', content });
          }
        });

        this.translateService.get(`${selector}-description`).subscribe({
          next: content => {
            this.meta.updateTag({ name: 'description', content });
            this.meta.updateTag({ property: 'og:description', content });
          }
        });

        /*   this.translateService.get(`${selector}.keywords`).subscribe(content => {
             this.meta.updateTag({name: 'keywords', content});
           })*/
      } else {
        this.title.setTitle(selector);
        this.meta.updateTag({ property: 'og:title', selector });
      }

    } else {
      //this.title.setTitle(this.config.project);
    }
  }

  private setCanonicalUrl(url: string | null): HTMLLinkElement | undefined {
    if (url) {
      const link: HTMLLinkElement = this.document.querySelector(`link[rel='canonical']`) || this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', url);
      this.document.head.appendChild(link);
      const currentLang = this.translateService.currentLang;

      // setup alternate
      this.document.querySelectorAll(`link[rel='alternate']`).forEach(a => a.remove());
      this.config.langs.forEach(l => {
        const alternate: HTMLLinkElement = this.document.createElement('link');
        const href = url.replace(currentLang, l.code);
        alternate.setAttribute('rel', 'alternate');
        alternate.setAttribute('href', href);
        alternate.setAttribute('hreflang', l.code);
        this.document.head.appendChild(alternate);
      });
      // setup x-default
      //if (currentLang !== 'en') {
      const def: HTMLLinkElement = this.document.createElement('link');
      def.setAttribute('rel', 'alternate');
      def.setAttribute('href', url.replace(currentLang, 'en'));
      def.setAttribute('hreflang', 'x-default');
      this.document.head.appendChild(def);
      //}
      return link;
    }
    this.document.querySelector(`link[rel='canonical']`)?.remove();
    this.document.querySelectorAll(`link[rel='alternate']`).forEach(a => a.remove());
    return;
  }
}

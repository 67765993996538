import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

/**
 * TranslateGuard is a route guard that handles language-based navigation.
 *
 * @description
 * This guard serves the following purposes:
 * 1. It retrieves the current language from the Language service or defaults to 'en'.
 * 2. It extracts the current navigation's query parameters.
 * 3. It redirects the user to the route with the current language code, preserving any query parameters.
 * 4. This ensures that all routes have a language prefix, facilitating proper localization.
 * 5. It's particularly useful for server-side rendering (SSR) where the language might not be set by default.
 */
export const TranslateGuard: CanActivateFn = () => {
  const router = inject(Router);
  const lang = inject(TranslateService).currentLang || 'en'; // ssr has no lang by default
  const currentNavigation = router.getCurrentNavigation();
  return router.navigate([lang], { queryParams: currentNavigation?.extractedUrl.queryParams });
};

import { Route, Routes } from '@angular/router';
import { PrivateAreaGuard } from '@bs/services/guards/private-area.guard';
import { TranslateGuard } from '@bs/services/guards/translate.guard';
import { UserLoggedGuard } from '@bs/services/guards/user-logged.guard';
import { BookmakersResolver } from '@bs/services/resolvers/core/bookmakers.resolver';
import { CurrenciesResolver } from '@bs/services/resolvers/core/currencies.resolver';
import { UserResolver } from '@bs/services/resolvers/core/user.resolver';

import { environment } from '@winchester/environments/environment';


const optionalRoutes: Routes = [];

if (environment.api.reports) {
  optionalRoutes.push({
    path: 'reports',
    resolve: { me: UserResolver },
    loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule)
  });
}

if (environment.api.accounts) {
  optionalRoutes.push({
      path: 'accounts',
      resolve: { me: UserResolver },
      loadChildren: () => import('./pages/accounts/accounts.module').then(m => m.AccountsModule)
    }, {
      path: 'wallets',
      canActivate: [PrivateAreaGuard],
      resolve: { me: UserResolver },
      loadChildren: () => import('./pages/wallets/wallets.module').then(m => m.WalletsModule)
    }
  );
}

if (environment.api.sportsbook) {
  optionalRoutes.push({
      path: 'sportsbook',
      resolve: { me: UserResolver },
      loadChildren: () => import('./pages/sportsbook/sportsbook.module').then(m => m.SportsbookModule)
    }
  );
}

if (environment.api.games) {
  optionalRoutes.push({
      path: 'games',
      resolve: { me: UserResolver },
      loadChildren: () => import('./pages/games/games.module').then(m => m.GamesModule)
    }
  );
}

if (environment.api.cms) {
  optionalRoutes.push({
      path: 'cms',
      resolve: { me: UserResolver },
      loadChildren: () => import('./pages/cms/cms.module').then(m => m.CmsModule)
    }
  );
}

if (environment.api.promo) {
  optionalRoutes.push({
      path: 'promo',
      resolve: { me: UserResolver },
      loadChildren: () => import('./pages/promotions/promotions.module').then(m => m.PromotionsModule)
    }
  );
}

if (environment.api.promo) {
  optionalRoutes.push({
      path: 'regulators',
      resolve: { me: UserResolver },
      loadChildren: () => import('./pages/regulators/regulators.module').then(m => m.RegulatorsModule)
    }
  );
}

export const appRoutes: Array<Route> = [
  {
    path: '',
    canActivate: [TranslateGuard],
    children: [],
    pathMatch: 'full'
  },
  {
    path: ':lang',
    resolve: { currencies: CurrenciesResolver },
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      { path: 'error', loadComponent: () => import('./pages/error/app.error.component').then(m => m.AppErrorComponent) },
      { path: 'auto-logout', loadComponent: () => import('./pages/error/app.accessdenied.component').then(m => m.AppAccessdeniedComponent) },
      { path: 'not-authorized', loadComponent: () => import('./pages/error/app.accessdenied.component').then(m => m.AppAccessdeniedComponent) },
      { path: 'not-found', loadComponent: () => import('./pages/error/app.notfound.component').then(m => m.AppNotfoundComponent) },
      {
        path: 'login',
        loadComponent: () => import('./pages/login/app.login.component').then(m => m.AppLoginComponent),
        canActivate: [UserLoggedGuard]
      },
      {
        path: '',
        loadComponent: () => import('./pages/main/app.main.component').then(m => m.AppMainComponent),
        resolve: { bookmakers: BookmakersResolver },
        children: [
          {
            path: 'me',
            canActivate: [PrivateAreaGuard],
            resolve: { me: UserResolver },
            loadChildren: () => import('./pages/me/me.module').then(m => m.MeModule)
          },
          ...optionalRoutes
        ]
      }
    ]
  },
  { path: '**', redirectTo: '../notfound' }
];
